import React, { useCallback, useEffect, useMemo } from 'react';
import { styled } from 'styles';

import logoImg from './logo.png';
import logo2Img from './logo2.png';
import { useTracker, useUTM } from 'modules/shared/hooks';

function BlackFriday2024DesktopBanner({ location }) {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const version = useMemo(() => {
    return 'Controle';
  }, []);

  const onOpenWpp = useCallback(() => {
    trackEvent('BF_24_2_VIP_Web: Clicou Banner', {
      variant: version,
      local: location,
      ...getUTMsByLocation(window.location),
    });

    const link =
      version === 'Controle'
        ? 'https://chat.whatsapp.com/BC10lWuQ1Xb3PU3evAboiv'
        : 'https://chat.whatsapp.com/KQLkpURbc9uBYfSEEzTTZN';
    window.open(link, '_blank');
  }, [trackEvent, getUTMsByLocation, version, location]);

  useEffect(() => {
    trackEvent('BF_24_2_VIP_Web: Visualizou Banner', {
      variant: version,
      local: location,
      ...getUTMsByLocation(window.location),
    });
  }, [trackEvent, getUTMsByLocation, version, location]);

  return (
    <Container
      onClick={onOpenWpp}
      style={{ background: version === 'Controle' ? 'black' : '#ffee00' }}
    >
      {version === 'Controle' ? (
        <Image src={logoImg} width="240px" />
      ) : (
        <Image src={logo2Img} width="150px" />
      )}
      <Text className={version === 'Controle' ? 'white' : 'black'}>
        {version === 'Controle' ? (
          <>
            <BoldAndOrange>NÃO ASSINE O RESPONDE AÍ!</BoldAndOrange> Vamos
            liberar <br />
            <Bold>o maior desconto do ano</Bold> na Black Friday <br />
            <Button className="black">Entre agora na lista de espera</Button>
          </>
        ) : (
          <>
            Quer concorrer a{' '}
            <BoldAndBlue>5 Tablets Samsung S6 Lite</BoldAndBlue> ?<br />
            <Button className="white">
              Entre agora no grupo do Responde Aí
            </Button>
          </>
        )}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img``;

const Text = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 1.4em;

  &.black {
    color: black;
  }
`;

const BoldAndOrange = styled.span`
  font-weight: bold;
  color: #f7ac3b;
`;

const BoldAndBlue = styled.span`
  font-weight: bold;
  color: red;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Button = styled.button`
  color: black;
  padding: 10px 30px;
  margin-top: 10px;
  border-radius: 40px;
  text-transform: uppercase;
  font-size: 0.65em;
  font-weight: bold;

  &.black {
    background-color: #ffee00;
    color: black;
  }

  &.white {
    background-color: red;
    color: #fff;
  }
`;

export default BlackFriday2024DesktopBanner;
