import React, { useCallback } from 'react';

import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';
import { useUserContext } from 'providers/UserProvider';
import BlackFriday2024MobileBanner from '../shared/BlackFriday2024/mobile-banner/BlackFriday2024MobileBanner';

function MobileBooksBanner() {
  const { bannersToShowInfo } = useBannerContext();
  const { user } = useUserContext();

  const getPriorityBanner = useCallback(() => {
    if (user && !user.subscriber)
      return <BlackFriday2024MobileBanner location="Book" />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker source="Book" />;
  }, [bannersToShowInfo, user]);

  return <Container>{getPriorityBanner()}</Container>;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileBooksBanner;
