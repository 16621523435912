import React, { useCallback, useEffect, useMemo } from 'react';

import { useTracker, useUTM } from 'modules/shared/hooks';
import { styled } from 'styles';
import logo2Img from '../desktop-banner/logo2.png';

function BlackFriday2024MobileBanner({ location }) {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const version = useMemo(() => {
    return 'Controle';
  }, []);

  const onOpenWpp = useCallback(() => {
    trackEvent('BF_24_2_VIP_Mobile: Clicou Banner', {
      variant: version,
      local: location,
      ...getUTMsByLocation(window.location),
    });

    const link =
      version === 'Controle'
        ? 'https://chat.whatsapp.com/BC10lWuQ1Xb3PU3evAboiv'
        : 'https://chat.whatsapp.com/KQLkpURbc9uBYfSEEzTTZN';
    window.open(link, '_blank');
  }, [trackEvent, getUTMsByLocation, version, location]);

  useEffect(() => {
    trackEvent('BF_24_2_VIP_Mobile: Visualizou Banner', {
      variant: version,
      local: location,
      ...getUTMsByLocation(window.location),
    });
  }, [trackEvent, getUTMsByLocation, version, location]);

  return (
    <Container
      onClick={onOpenWpp}
      className={version === 'Controle' ? '' : 'white'}
    >
      {version === 'Controle' ? (
        <>
          <Text>
            <BoldAndOrange>NÃO ASSINE O RESPONDE AÍ!</BoldAndOrange> Vamos
            liberar <br />
            <Bold>o maior desconto do ano</Bold> na Black Friday
          </Text>
          <Button>Entre agora na lista de espera</Button>
        </>
      ) : (
        <>
          <Image src={logo2Img} />
          <Text className={version === 'Controle' ? '' : 'black'}>
            Quer concorrer a{' '}
            <BoldAndBlue>5 Tablets Samsung S6 Lite</BoldAndBlue>? <br />
            <Button className={version === 'Controle' ? '' : 'blue'}>
              Entre agora no grupo do Responde Aí
            </Button>
          </Text>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.white {
    background-color: #ffee00;
    flex-direction: row;
  }
`;

const Text = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 0.8em;

  &.black {
    color: black;
  }
`;

const BoldAndOrange = styled.span`
  font-weight: bold;
  color: #f7ac3b;
`;

const BoldAndBlue = styled.span`
  font-weight: bold;
  color: red;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Button = styled.button`
  background-color: #ffee00;
  color: black;
  padding: 5px 20px;
  border-radius: 40px;
  text-transform: uppercase;
  font-size: 0.6em;
  font-weight: bold;

  &.blue {
    color: white;
    background-color: red;
  }
`;

const Image = styled.img`
  width: 50px;
`;

export default BlackFriday2024MobileBanner;
