import React, { useCallback, useMemo } from 'react';
import { DesktopBannerMaker } from 'modules/banner/shared/banners';
import { media, styled } from 'styles';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { useUserContext } from 'providers/UserProvider';
import { useBannerContext } from 'providers/BannerProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import MvpThemesListBanner from 'modules/campaigns/mvp-books/banners/MvpThemesListBanner';
import BlackFriday2024DesktopBanner from '../shared/BlackFriday2024/desktop-banner/BlackFriday2024DesktopBanner';

export default function WorkspaceBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();
  const { shouldShowListWorkspaceBanner } = useMvpBooksEligible();

  const getPriorityBanner = useCallback(() => {
    if (user && !user.subscriber)
      return (
        <BlackFriday2024DesktopBanner height="140px" location="Workspace" />
      );
    if (bannersToShowInfo?.workspaceBannerName === 'workspaceBannerMaker')
      return <DesktopBannerMaker source="Workspace" />;
    if (bannersToShowInfo && shouldShowListWorkspaceBanner())
      return (
        <MvpThemesListBanner origin={'workspace'} utmContent="workspace" />
      );
    return null;
  }, [bannersToShowInfo, shouldShowListWorkspaceBanner, user]);

  const banner = useMemo(() => {
    return getPriorityBanner();
  }, [getPriorityBanner]);

  const canShowWorkspaceBanner = useMemo(() => {
    return user && banner && media.isDesktop();
  }, [user, banner]);

  const isToggleableBanner = useCallback(() => {
    if (
      user?.subscriber &&
      bannersToShowInfo?.workspaceBannerName === 'workspaceBannerMaker'
    ) {
      return false;
    }
    return (
      canShowWorkspaceBanner &&
      bannersToShowInfo?.workspaceBannerName !== 'linkedinBanner' &&
      bannersToShowInfo?.workspaceBannerName !== 'plannerBanner'
    );
  }, [canShowWorkspaceBanner, bannersToShowInfo, user?.subscriber]);

  if (!canShowWorkspaceBanner) return null;

  return isToggleableBanner() ? (
    <ToggleableComponent
      renderDelay={1500}
      open={true}
      height={banner.props.height || '120px'}
    >
      <Container height={banner.props.height}>{banner}</Container>
    </ToggleableComponent>
  ) : (
    <Container height={banner.props.height}>{banner}</Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 5px;
  height: ${({ height }) => (height ? height : '120px')};
  cursor: pointer;
`;
