import React, { useCallback } from 'react';
import {
  ChangePlanMobile,
  SolvedBooksMobile,
  UniversityPersonalizedContentBannerMobile,
} from './banners';

import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';
import { PerpetualPlansBannerMobile } from 'modules/campaigns/perpetual-plans/banners';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import MvpThemesListBanner from 'modules/campaigns/mvp-books/banners/MvpThemesListBanner';
import { useUserContext } from 'providers/UserProvider';
import BlackFriday2024MobileBanner from '../shared/BlackFriday2024/mobile-banner/BlackFriday2024MobileBanner';

function MobileFrontbanner() {
  const { user } = useUserContext();
  const { isBannerEligibleInHomePage } = useMvpBooksEligible();
  const { bannersToShowInfo } = useBannerContext();

  const getPriorityBanner = useCallback(() => {
    if (user && !user.subscriber)
      return <BlackFriday2024MobileBanner location="Home" />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker />;
    if (bannersToShowInfo && isBannerEligibleInHomePage())
      return <MvpThemesListBanner origin="Matérias" utmContent="pag_home" />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBannerMobile />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'perpetualPlans')
      return <PerpetualPlansBannerMobile />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'changePlan')
      return <ChangePlanMobile />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'solvedBooks')
      return <SolvedBooksMobile />;
    return null;
  }, [bannersToShowInfo, isBannerEligibleInHomePage, user]);

  return <Container id="mobile-front-banner">{getPriorityBanner()}</Container>;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileFrontbanner;
